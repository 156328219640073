import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {api} from "../../frontend/components/api";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import "./EnrollmentsTable.scss";

export function EnrollmentsTable() {
    const { loginWithPopup, logout, user, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    const [enrollments, setEnrollments] = React.useState([]);
    const [lessons, setLessons] = React.useState([]);

    React.useEffect(() => {
        if (isAuthenticated) {
            api.getEnrollments().then((enrollments) => {
                setEnrollments(enrollments);
            });

            api.getLessons().then((lessons) => {
                setLessons(lessons);
            });
        }
    }, [isAuthenticated]);

    const findLessonName = (lessonId) => {
        const lesson = lessons.find((lesson) => {
            return lesson._id === lessonId;
        });

        if (lesson) {
            return lesson.name;
        } else {
            return "Unknown Lesson";
        }
    }

    const handleEnrollmentRowClicked = (enrollment) => {
        navigate(`/enrollments/${enrollment._id}`);
    };

    return (
        <TableContainer component={Paper} className={"enrollments-table"}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Lesson</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {enrollments.map((enrollment) => (
                        <TableRow
                            key={enrollment._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => handleEnrollmentRowClicked(enrollment)}
                        >
                            <TableCell>{enrollment.phone_number}</TableCell>
                            <TableCell>{findLessonName(enrollment.lesson_id)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}