import {PageWrapper} from "../../frontend/app/PageWrapper";
import React from "react";
import "../../frontend/pages/HomePage.scss";
import {LessonsTable} from "./LessonsTable";
import {useNavigate} from "react-router-dom";
import {api} from "../../frontend/components/api";
import {SpinnerButton} from "../../frontend/components/SpinnerButton";


export function LessonListPage() {
    const navigate = useNavigate();

    const handleNewLessonClicked = async () => {
        const lesson = await api.createNewLesson({
            name: "New Lesson"
        });
        navigate(`/lessons/${lesson._id}`);
    };


    return <PageWrapper>
        <h1>Lesson Bots</h1>

        <div>
            <SpinnerButton className={"btn btn-primary"}
                    variant={"contained"}
                    onClick={handleNewLessonClicked}>
                New Lesson Bot
            </SpinnerButton>
        </div>

        <LessonsTable/>
    </PageWrapper>
}

