import React, {useState} from "react";
import "../../frontend/pages/HomePage.scss";
import {api} from "../../frontend/components/api";
import {TextField} from "@mui/material";
import "./LessonBulkSend.scss";
import {SpinnerButton} from "../../frontend/components/SpinnerButton";

export function LessonBulkSend(props) {
    const [message, setMessage] = useState(null);

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendMessageClicked = async () => {
        return api.bulkSendMessage(props.lesson, message);
    };

    return <div className="lesson-bulk-send-form">
        <span className={"help-text"}>
            This allows you to send a message in bulk to every user currently enrolled into a particular bot.
        </span>
        <TextField
            name="message"
            label="Message"
            placeholder={"Message to send to all subscribers to this lesson bot"}
            multiline
            rows={3}
            value={message}
            onChange={handleInputChange}
        />
        <div className={"buttons-row"}>
            <SpinnerButton
                className={"save-button"}
                variant="contained"
                color="primary"
                onClick={handleSendMessageClicked}
            >
                Send to Everyone Enrolled
            </SpinnerButton>
        </div>
    </div>;
}