import {TopBar} from "../menu/TopBar";
import LeftSideMenu from "../menu/LeftSideMenu";
import React from "react";

export const PageWrapper = ({children}) => {
    return <div className="App">
        <TopBar/>
        <div className={"below-top-bar"}>

            <LeftSideMenu/>

            <div className={"main-content-area"}>
                {children}
            </div>
        </div>
    </div>
};