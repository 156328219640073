import JSTextDecoderStream from "./JSTextDecoderStream";


/**
 * This is basically just a clone of the built in javascript EventSource class, except that it is able
 * submit a body in its request, use http methods other then GET (such as POST),
 * and attach arbitrary headers to the initial request (such as authentication)
 */
export default class SSEStreamer extends EventTarget {
    messageEventLinePrefix = "event: "
    messageDataLinePrefix = "data: "

    constructor(url, method, postBody, headers) {
        super();

        this.url = url;
        this.method = method;
        this.body = postBody;
        this.headers = headers;

        this.queuedLines = [];

        this.startRequest();
    }

    startRequest() {
        const requestOptions = {
            method: this.method,
            headers: {
                ...this.headers,
            },
            body: this.body ? this.body : null
        };

        fetch(this.url, requestOptions)
            .then(this.handleFetchSuccessResponse.bind(this))
            .catch(this.handleFetchErrorResponse.bind(this));
    }

    handleFetchErrorResponse(error) {
        console.warn("Error in SSE stream at url:", this.url, error.toString());
        this.dispatchEvent(new MessageEvent("error", {data: {error, stack: error.stack, error_string: error.toString()}}));
    }


    async handleFetchSuccessResponse(response) {
        try {
            const reader = response.body.pipeThrough(new JSTextDecoderStream()).getReader()

            while (true) {
                const {value, done} = await reader.read();
                if (done) break;

                this.processDataChunk(value);

                this.flushEventsFromQueuedLines(false);
            }

            // Flush any remaining events that weren't yet flushed
            this.flushEventsFromQueuedLines(true);

            // Send a close event.
            this.dispatchEvent(new MessageEvent("close"));
        } catch (err) {
            console.error("Error in SSE stream", err);
            throw err;
        }
    }


    processDataChunk(text) {
        const chunkLines = text.split('\n');

        // The very first line should have its value appended to the value of the last line
        if (this.queuedLines.length === 0) {
            this.queuedLines.push(chunkLines[0]);
        } else {
            this.queuedLines[this.queuedLines.length - 1] += chunkLines[0];
        }

        for (const line of chunkLines.slice(1)) {
            this.queuedLines.push(line);
        }
    }



    flushEventsFromQueuedLines(finished) {
        while (this.queuedLines.length >= 3) {
            const [eventLine, dataLine, emptyLine] = this.queuedLines.splice(0, 3);

            if (emptyLine !== "") {
                const msg = `Error in SSE stream decoding: Expected empty line, got ${JSON.stringify(emptyLine)}`;
                console.error(msg);
            }

            const event = eventLine.substring(this.messageEventLinePrefix.length);
            const data = dataLine.substring(this.messageDataLinePrefix.length);

            this.dispatchEvent(new MessageEvent(event, {data}));
        }
    }

    /**
     * Close function. Technically this does nothing, but its kept to maintain compatibility with the EventSource API
     */
    close() {
        // Dispatch a close event.
        this.dispatchEvent(new MessageEvent("close"));
    }
}
