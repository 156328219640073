import { Component } from "react";
import "./TopBar.scss";
import AccountMenu from "./AccountMenu";

export class TopBar extends Component {

    render() {
        return <div className={"top-bar"}>

            <div className={"logo-area"}>
                <div className={"logo-image"}>
                    <img src={"/logo.png"} alt={"logo"} className={"logo-image"}/>
                </div>
                <span className={"logo-text"}>CoralBot</span>
            </div>

            <AccountMenu/>

        </div>
    }
}
