import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {api} from "../../frontend/components/api";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import "./LessonsTable.scss";

export function LessonsTable() {
    const { loginWithPopup, logout, user, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    const [lessons, setLessons] = React.useState([]);

    React.useEffect(() => {
        if (isAuthenticated) {
            api.getLessons().then((lessons) => {
                setLessons(lessons);
            });
        }
    }, [isAuthenticated]);

    const handleLessonRowClicked = (lesson) => {
        navigate(`/lessons/${lesson._id}`);
    };

    return (
        <TableContainer component={Paper} className={"lessons-table"}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lessons.map((lesson) => (
                        <TableRow
                            key={lesson._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={() => handleLessonRowClicked(lesson)}
                        >
                            <TableCell>{lesson.name}</TableCell>
                            <TableCell>{lesson.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}