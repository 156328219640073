import {PageWrapper} from "../app/PageWrapper";
import React from "react";
import "./HomePage.scss";


export function HomePage() {
    return <PageWrapper>
        <p>Please use the left menu to access the functionality</p>
    </PageWrapper>
}

