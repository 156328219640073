import {PageWrapper} from "../../frontend/app/PageWrapper";
import React from "react";
import "../../frontend/pages/HomePage.scss";
import {useNavigate} from "react-router-dom";
import {api} from "../../frontend/components/api";
import {SpinnerButton} from "../../frontend/components/SpinnerButton";
import {EnrollmentsTable} from "./EnrollmentsTable";


export function EnrollmentsListPage() {
    const navigate = useNavigate();

    const handleNewEnrollmentClicked = async () => {
        const enrollment = await api.createNewEnrollment();
        navigate(`/enrollments/${enrollment._id}`);
    };


    return <PageWrapper>
        <h1>Enrollments</h1>

        <div>
            <SpinnerButton className={"btn btn-primary"}
                           variant={"contained"}
                           onClick={handleNewEnrollmentClicked}>
                Enroll Phone Number
            </SpinnerButton>
        </div>

        <EnrollmentsTable/>
    </PageWrapper>
}

