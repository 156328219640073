import React, {useState} from 'react';
import {Button, TextField} from '@mui/material';
import "./BotEvaluationWidget.scss";
import {api} from "../../../frontend/components/api";
import {SpinnerButton} from "../../../frontend/components/SpinnerButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ConversationsTable = ({conversations}) => {
    return <TableContainer component={Paper}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Scenario #</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Expected / User Message</TableCell>
                    <TableCell>Actual Message From Bot</TableCell>
                    <TableCell>Should Follow Up</TableCell>
                    <TableCell>Did Bot Follow Up?</TableCell>
                    <TableCell>Follow Up Completion</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    conversations.map((conversation, conversationIndex) => {
                        return conversation.messages.map((message, messageIndex) => {
                            return <TableRow key={`${conversationIndex}-${messageIndex}`}>
                                <TableCell>{conversation.scenario_number}</TableCell>
                                <TableCell>{message.role}</TableCell>
                                <TableCell>{message.time}</TableCell>
                                <TableCell>{message.message}</TableCell>
                                <TableCell>{message.actual_message_from_bot}</TableCell>
                                <TableCell>{message.actual_follow_up_raw_completion ? (message.should_follow_up === true ? "yes" : "no") : ""}</TableCell>
                                <TableCell>{message.actual_follow_up_raw_completion ? (message.actual_follow_up_result === true ? "yes" : "no") : ""}</TableCell>
                                <TableCell>{message.actual_follow_up_raw_completion}</TableCell>
                            </TableRow>
                        })
                    })
                }
            </TableBody>
        </Table>
    </TableContainer>;
}

const ResultSummaryStatistics = ({conversations}) => {
    let correctFollowUps = 0;
    let totalNumberOfFollowUpTests = 0;
    let followUpFalsePositives = 0;
    let followUpFalseNegatives = 0;

    conversations.forEach(conversation => {
        conversation.messages.forEach(message => {
            if (message.actual_follow_up_raw_completion) {
                totalNumberOfFollowUpTests++;

                if (message.should_follow_up === message.actual_follow_up_result) {
                    correctFollowUps++;
                } else {
                    if (message.should_follow_up === true) {
                        followUpFalseNegatives++;
                    } else {
                        followUpFalsePositives++;
                    }
                }
            }
        });
    });

    return (
        <div className={"summary-widget"}>
            <h2>Follow Up Summary Stats</h2>
            <p>Number of correct follow-ups: {correctFollowUps}</p>
            <p>Number of false positives: {followUpFalsePositives}</p>
            <p>Number of false negatives: {followUpFalseNegatives}</p>
            <p>Total follow-up tests: {totalNumberOfFollowUpTests}</p>
            <p>Percentage of correct follow-ups: {(100 * correctFollowUps / totalNumberOfFollowUpTests).toFixed(1)}%</p>
        </div>
    );
};

const BotEvaluationWidget = ({lesson}) => {
    const [file, setFile] = useState(null);
    const [conversations, setConversations] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file before uploading');
            return;
        }

        return new Promise((resolve, reject) => {
            const conversations = [];
            const stream = api.evaluateBot(lesson._id, file);
            stream.addEventListener('result', (event) => {
                const result = JSON.parse(event.data);
                conversations.push(result);
                setConversations(conversations)
            });
            stream.addEventListener('finish', (event) => {
                resolve();
            });
            stream.addEventListener('error', (event) => {
                console.error(event.data);
                reject();
            });
            stream.addEventListener('close', (event) => {
                resolve();
            });
        });
    };

    return (
        <div className={"bot-evaluation-widget"}>
            <h2>Bot Evaluation</h2>
            <p>Here you can upload a CSV file that will be used to systematically evaluate the accuracy of the bot.</p>
            <TextField
                type="file"
                variant="outlined"
                onChange={handleFileChange}
            />
            <SpinnerButton variant="contained" color="primary" onClick={handleUpload}>
                Upload
            </SpinnerButton>

            {
                conversations ?
                    <div>
                        <h2>Results</h2>
                        <ResultSummaryStatistics
                            conversations={conversations}
                        />
                        <br/>
                        <h2>Detailed</h2>
                        <ConversationsTable
                            conversations={conversations}
                        />
                    </div>
                    : null
            }
        </div>
    );
};

export default BotEvaluationWidget;
