import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../utils/utils";
import {createContext} from "react";
import { axiosETAGCache } from 'axios-etag-cache';
import SSEStreamer from "./SSEStreamer";
export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

export const api = {
    async getCompletion(prompt) {
        const queryParams = {
            prompt: prompt
        }

        const response = await axios.get(`/completion`, {
            params: queryParams
        });

        return response.data;
    },
    async getLessons() {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/lesson`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewLesson(newLessonData) {
        const response = await ensureMinimumPromiseResolveTime(axios.post(`/lesson`, newLessonData), 500);

        return response.data;
    },
    async getLesson(lessonId) {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/lesson/${lessonId}`, {
            params: queryParams
        });

        return response.data;
    },
    async deleteLesson(lessonId) {
        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/lesson/${lessonId}`), 500);

        return response.data;
    },
    async saveLesson(lesson) {
        const response = await ensureMinimumPromiseResolveTime(axios.put(`/lesson/${lesson._id}`, lesson), 500);
        return response.data;
    },
    async bulkSendMessage(lesson, message) {
        const data = {message};

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/lesson/${lesson._id}/bulk-send`, data), 500);
        return response.data;
    },
    async getEnrollments() {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/enrollment`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewEnrollment() {
        const newEnrollmentData = {

        }

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/enrollment`, newEnrollmentData), 500);

        return response.data;
    },
    async getEnrollment(enrollmentId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/enrollment/${enrollmentId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveEnrollment(enrollment) {
        const response = await ensureMinimumPromiseResolveTime(axios.put(`/enrollment/${enrollment._id}`, enrollment), 500);
        return response.data;
    },
    async deleteEnrollment(enrollmentId) {
        const queryParams = {};

        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/enrollment/${enrollmentId}`), 500);

        return response.data;
    },
    async sendInitialMessageForEnrollment(enrollmentId) {
        const queryParams = {};

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/enrollment/${enrollmentId}/send_initial`), 500);

        return response.data;
    },
    async getRecentMessages(enrollment_id) {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.get(`/enrollment/${enrollment_id}/recent_messages`, {
            params: queryParams
        });

        return response.data;
    },
    async checkFollowUp(enrollment_id) {
        const queryParams = {
        }

        const response = await axiosWithETAGCache.post(`/enrollment/${enrollment_id}/follow_up`, {
            params: queryParams
        });

        return response.data;
    },
    evaluateBot(lesson_id, file) {
        const formData = new FormData();
        formData.append('file', file);

        // Make the streaming call with the same headers as a normal axios call, which will include the
        // authentication token if it's available
        const headers = {
            ...axios.defaults.headers.common,
        };

        const stream = new SSEStreamer(`${process.env.REACT_APP_BACKEND_API_URL}lesson/${lesson_id}/evaluate`, 'POST', formData, headers);

        return stream;
    },
}

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error)
{
    if (error.response && error.response.status === 401)
    {
        // Auth0.logout();
        return (Promise.reject(error));
    }

    if (process.env.REACT_APP_DEBUG === "true")
    {
        alert(error.toString());
    } else {
        // Force reload the page. maybe this will help.
        // window.location.reload();
    }
    // Do something with response error
    return (Promise.reject(error));
});


