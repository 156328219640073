import "./LeftSideMenu.scss";
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {useAuth0} from "@auth0/auth0-react";
import {NavLink} from "react-router-dom";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleIcon from '@mui/icons-material/Article';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


export default function LeftSideMenu() {
    const {loginWithPopup, isAuthenticated} = useAuth0();

    let menuList = null;
    if (!isAuthenticated) {
        menuList = <div className={"menu-chart-list-wrapper unauthenticated"}>
            <span className={"please-login-text"}>Please login to edit lessons</span>
            <br/>
            <Button className={"login-button"} onClick={loginWithPopup} variant={"outlined"}>Login</Button>
        </div>
    } else {
        menuList = <div className={"menu-pages-wrapper"}>
            <List>
                <ListItem disablePadding>
                    <NavLink
                        to={`/lessons`}
                        className={({isActive, isPending}) => {
                            return isActive ? "active" : isPending ? "pending" : "";
                        }}
                    >
                    <ListItemButton>
                            <ListItemIcon>
                                <ArticleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Lesson Bots"/>
                    </ListItemButton>
                    </NavLink>
                </ListItem>
                <ListItem disablePadding>
                    <NavLink
                        to={`/enrollments`}
                        className={({isActive, isPending}) => {
                            return isActive ? "active" : isPending ? "pending" : "";
                        }}
                    >
                    <ListItemButton>
                        <ListItemIcon>
                            <LocalPhoneIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Enrollments"/>
                    </ListItemButton>
                    </NavLink>
                </ListItem>
            </List>
        </div>
    }

    return (
        <div className={"left-side-menu"}>
            {menuList}
        </div>
    );
}