import {PageWrapper} from "../../frontend/app/PageWrapper";
import React, {useCallback, useState} from "react";
import "../../frontend/pages/HomePage.scss";
import {api} from "../../frontend/components/api";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {TextField, Card, CardContent, CardHeader, Tabs, Tab} from "@mui/material";
import "./LessonEditPage.scss";
import {SpinnerButton} from "../../frontend/components/SpinnerButton";
import {LessonBulkSend} from "./LessonBulkSend";
import BotEvaluationWidget from "../../bot/pages/evaluation/BotEvaluationWidget";

export function LessonEditPage(props) {
    const {isAuthenticated} = useAuth0();
    const params = useParams();

    const navigate = useNavigate();
    const [lesson, setLesson] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    React.useEffect(() => {
        if (isAuthenticated && !lesson) {
            api.getLesson(params?.lessonId).then((lesson) => {
                setLesson(lesson);
            });
        }
    }, [isAuthenticated, lesson, params?.lessonId]);

    const handleInputChange = (event) => {
        setLesson({
            ...lesson,
            [event.target.name]: event.target.value
        });
    };

    const handleSaveClicked = async () => {
        return await api.saveLesson(lesson);
    };

    const handleDeleteClicked = async () => {
        return await api.deleteLesson(lesson._id).then(() => {
            navigate(`/lessons`);
        })
    };

    const handleTabChanged = useCallback((event, newTab) => {
        setSelectedTab(newTab);
    }, []);

    if (!lesson) {
        return null;
    }

    return <PageWrapper>
        <div className="lesson-edit-form">
            <h1>Edit Lesson Bot - {lesson?.name}</h1>

            <Card className="lesson-edit-form-card">
                <CardContent>
                    <Tabs value={selectedTab} onChange={handleTabChanged} aria-label="Editor Tabs">
                        <Tab label="General"/>
                        <Tab label="Responses"/>
                        <Tab label="Follow Ups"/>
                        <Tab label="Bulk Send"/>
                        <Tab label="Evaluation"/>
                    </Tabs>
                    {
                        selectedTab === 0 ?
                            <>
                                <span className={"help-text"}>
                                    The name and description are only used internally and do not impact the behavior of the bot. They are just for your own reference.
                                </span>
                                <TextField
                                    name="name"
                                    label="Name"
                                    placeholder={"The name of the lesson"}
                                    value={lesson.name}
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    name="description"
                                    label="Description"
                                    placeholder={"A description of the lesson to be taught"}
                                    multiline
                                    rows={2}
                                    value={lesson.description}
                                    onChange={handleInputChange}
                                />
                                <span className={"help-text"}>
                                    The initial enrollment message is the message that the user receives when you first enroll a phone number and press the "Send Initial Message" button.
                                </span>
                                <TextField
                                    name="initial_enrollment_message"
                                    label="Initial Enrollment Message"
                                    multiline
                                    rows={6}
                                    value={lesson.initial_enrollment_message}
                                    onChange={handleInputChange}
                                />
                            </> : null
                    }
                    {
                        selectedTab === 1 ?
                            <>
                                <span className={"help-text"}>
                                    These are instructions given to the bot to decide how it should respond to any given message it receives.
                                    <br/>
                                    You will need to include lots of detail for this to work.
                                </span>
                                <TextField
                                    name="instructions"
                                    label="Response Instructions"
                                    placeholder={"The instructions to the chat-bot on what to say and how to respond"}
                                    multiline
                                    rows={30}
                                    value={lesson.instructions}
                                    onChange={handleInputChange}
                                />
                            </> : null
                    }
                    {
                        selectedTab === 2 ?
                            <>
                                <span className={"help-text"}>
                                    These are instructions given to the bot for it to decide whether or not it should follow up with the user at any given moment.
                                    <br/>
                                    Make sure you instruct the bot to give you an explanation followed by a hard "yes/no" answer, in addition to whatever
                                    specific instructions you have.
                                </span>
                                <TextField
                                    name="follow_up_check_instructions"
                                    label="Follow up check instructions"
                                    multiline
                                    rows={12}
                                    value={lesson.follow_up_check_instructions}
                                    onChange={handleInputChange}
                                />
                                <span className={"help-text"}>
                                    These are instructions given to the bot to decide what message it should follow up with at any given time.
                                    <br/>
                                    Make sure you include context on the bot, similar to what you would include in the response instructions
                                </span>
                                <TextField
                                    name="follow_up_message_instructions"
                                    label="Follow up message instructions"
                                    multiline
                                    rows={12}
                                    value={lesson.follow_up_message_instructions}
                                    onChange={handleInputChange}
                                />
                            </> : null
                    }
                    {
                        selectedTab === 0 || selectedTab === 1 || selectedTab === 2 ?
                            <div className={"buttons-row"}>
                                <SpinnerButton
                                    className={"save-button"}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveClicked}
                                >
                                    Save
                                </SpinnerButton>
                                <SpinnerButton
                                    variant="contained"
                                    color="error"
                                    className={"delete-button"}
                                    onClick={handleDeleteClicked}
                                >
                                    Delete
                                </SpinnerButton>
                            </div> : null
                    }
                    {
                        selectedTab === 3 ?
                            <>
                                <LessonBulkSend lesson={lesson} />
                            </>
                            : null
                    }
                    {
                        selectedTab === 4 ?
                            <>
                                <BotEvaluationWidget lesson={lesson} />
                            </>
                            : null
                    }
                </CardContent>
            </Card>
        </div>

    </PageWrapper>
}