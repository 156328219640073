import {PageWrapper} from "../../frontend/app/PageWrapper";
import React, {useState} from "react";
import "../../frontend/pages/HomePage.scss";
import {api} from "../../frontend/components/api";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {TextField, Card, CardContent, Select, MenuItem, Alert} from "@mui/material";
import "./EnrollmentEditPage.scss";
import {SpinnerButton} from "../../frontend/components/SpinnerButton";
import Avatar from "@mui/material/Avatar";

export function EnrollmentEditPage(props) {
    const {isAuthenticated} = useAuth0();
    const params = useParams();

    const navigate = useNavigate();
    const [enrollment, setEnrollment] = useState(null);
    const [lessons, setLessons] = React.useState([]);
    const [messages, setMessages] = React.useState([]);
    const [followUpCheckResponse, setFollowUpCheckResponse] = React.useState(null);

    const phoneNumbers = [
        {
            name: "+44 (789) 703 6376",
            value: "447897036376",
        },
        {
            name: "+1 (415) 813-6604",
            value: "14158136604",
        },
        {
            name: "+1 (289) 207-5186",
            value: "12892075186",
        }
    ];

    React.useEffect(() => {
        if (isAuthenticated && !enrollment) {
            api.getEnrollment(params?.enrollmentId).then((enrollment) => {
                setEnrollment(enrollment);
            });
            api.getLessons().then((lessons) => {
                setLessons(lessons);
            });
            api.getRecentMessages(params?.enrollmentId).then((data) => {
                const reversedMessaged = data.messages;
                reversedMessaged.reverse();
                setMessages(reversedMessaged);
                // setMessages([
                //     {
                //         "created_at": "2021-10-17T20:00:00.000Z",
                //         "role": "user",
                //         "content": "hello biznatches"
                //     },
                //     {
                //         "created_at": "2021-10-17T21:00:00.000Z",
                //         "role": "assistant",
                //         "content": "how are you doing today sir?"
                //     }
                // ]);
            });
        }
    }, [isAuthenticated, enrollment, params?.enrollmentId]);

    const handleInputChange = (event) => {
        setEnrollment({
            ...enrollment,
            [event.target.name]: event.target.value
        });
    };

    const handleLessonChange = (event) => {
        setEnrollment({
            ...enrollment,
            lesson_id: event.target.value
        });
    };

    const handleBotNumberChange = (event) => {
        setEnrollment({
            ...enrollment,
            bot_number: event.target.value
        });
    };

    const handleSaveClicked = async () => {
        return await api.saveEnrollment(enrollment);
    };


    const handleDeleteClicked = async () => {
        return await api.deleteEnrollment(enrollment._id).then(() => {
            navigate(`/enrollments`);
        })
    };

    const handleCheckFollowUpClicked = async () => {
        return await api.checkFollowUp(enrollment._id).then((response) => {
            setFollowUpCheckResponse(response);
        })
    };
    const handleSendInitialMessageClicked = async () => {
        return await api.sendInitialMessageForEnrollment(enrollment._id).then(() => {

        })
    };

    if (!enrollment || !lessons) {
        return null;
    }

    return <PageWrapper>
        <div className="enrollment-edit-page">
            <h1>Edit Enrollment {enrollment?.phone_number ?? params?.enrollmentId}</h1>


            <Card className="enrollment-edit-form-card">
                <CardContent>
                    <TextField
                        name="phone_number"
                        label="Phone Number"
                        value={enrollment.phone_number}
                        onChange={handleInputChange}
                    />
                    <span className={"field-help"}>** Make sure you include the country code!</span>


                    <span className={"field-label"}>Lesson</span>
                    <Select
                        name="lesson_id"
                        label={"Lesson"}
                        value={enrollment.lesson_id}
                        onChange={handleLessonChange}
                    >
                        {lessons.map((lesson) => (
                            <MenuItem value={lesson._id}>{lesson.name}</MenuItem>
                        ))}
                    </Select>

                    <span className={"field-label"}>Bot Number</span>
                    <Select
                        name="bot_number"
                        label={"Bot Number"}
                        value={enrollment.bot_number}
                        onChange={handleBotNumberChange}
                    >
                        {phoneNumbers.map((phoneNumber) => (
                            <MenuItem value={phoneNumber.value}>{phoneNumber.name}</MenuItem>
                        ))}
                    </Select>
                    <div className={"buttons-row"}>
                        <SpinnerButton
                            className={"save-button"}
                            variant="contained"
                            color="primary"
                            onClick={handleSaveClicked}
                        >
                            Save
                        </SpinnerButton>
                        <SpinnerButton
                            variant="contained"
                            color="success"
                            className={"send-initial-button"}
                            onClick={handleSendInitialMessageClicked}
                        >
                            Send Initial Message
                        </SpinnerButton>
                        <SpinnerButton
                            variant="contained"
                            color="error"
                            className={"delete-button"}
                            onClick={handleDeleteClicked}
                        >
                            Delete
                        </SpinnerButton>
                    </div>
                </CardContent>
            </Card>

            <Card className="enrollment-messages-card">
                <CardContent>
                    <h1>Recent Messages</h1>
                    <div className={"messages-list"}>
                        {messages.map((message) => (
                            <div className={`message ${message.role}`}>
                                <Alert variant="filled" severity={message.role === 'user' ? 'info' : 'success'} icon={null}>
                                    <div className={"message-date"}>{new Date(message.created_at).toLocaleString()}</div>
                                    <div className={"message-role"}>
                                        {message.role === "user" ?
                                            <Avatar/> :
                                            <Avatar alt="Remy Sharp" src="/logo.png"/>
                                        }
                                    </div>
                                    <div className={"message-text"}>{message.content}</div>
                                </Alert>
                            </div>
                        ))}
                    </div>
                    <div className={"buttons-row"}>
                        <SpinnerButton
                            variant="contained"
                            color="warning"
                            className={"check-follow-up-button"}
                            onClick={handleCheckFollowUpClicked}
                        >
                            Check Follow Up
                        </SpinnerButton>
                    </div>
                    {
                        followUpCheckResponse ? <div className={"follow-up-check-response"}>
                            <div className={"follow-up-check-response-title"}>Follow Up Check</div>
                            <div className={"follow-up-check-response-needed_follow_up"}>Needed Follow Up: {followUpCheckResponse?.needed_follow_up ? "Yes" : "No"}</div>
                            <div className={"follow-up-check-response-raw_completion"}>Raw AI Response: {followUpCheckResponse?.raw_completion}</div>
                        </div>
                        : null
                    }
                </CardContent>
            </Card>
        </div>

    </PageWrapper>
}